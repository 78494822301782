import { useState, useRef, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import AzureBlobClient from '../lib/azureBlobClient';
import { useSetAtom, useAtomValue } from 'jotai';
import { uploadingAtom, doneAtom } from '../hooks/useSharedUploadState';

const FileUploadPage = () => {
  const requiredFileTypes = [".obj", ".gltf"];
  const [files, setFiles] = useState([]);
  const intervalIdRef = useRef(null);
  const uploadingState = useAtomValue(uploadingAtom);
  const setUploadingState = useSetAtom(uploadingAtom);
  const uploadDone = useAtomValue(doneAtom);
  const setUploadDone = useSetAtom(doneAtom);

  // Pass the functions to AzureBlobClient
  const azureClient = new AzureBlobClient();

  const onDrop = useCallback((acceptedFiles) => {
    const hasRequiredFile = acceptedFiles.some(file =>
      requiredFileTypes.some(ext => file.name.endsWith(ext))
    );

    if (!hasRequiredFile) {
      alert(`Check!: ${requiredFileTypes.join(' or ')} files are required.`);
      return;
    }

    const filesWithFullPath = acceptedFiles.map(file => ({
      ...file,
      fullPath: file.path || file.webkitRelativePath || file.name,
      size: file.size,
      content: new Blob([file]),
    })).map(file => {
      const fullPath = file.fullPath.startsWith('/') ? file.fullPath.substring(1) : file.fullPath;
      return {
        ...file,
        fullPath,
        name: fullPath.split('/').pop(),
      };
    });

    setFiles(prevFiles => [...prevFiles, ...filesWithFullPath]);
  }, []);

  const handleUpload = () => {
    if (files.length === 0) {
      alert('No files to upload.');
      return;
    }

    // Start uploading files and set uploading state
    setUploadingState(true);

    const filesWithNames = files.map(file => {
      const fullPath = file.fullPath.startsWith('/') ? file.fullPath.substring(1) : file.fullPath;
      return {
        ...file,
        fullPath,
        name: fullPath.split('/').pop(),
      };
    });

    const targetDirectory = azureClient.handleFileUpload(filesWithNames, () => {
      // When upload is complete, set done state
      setUploadingState(false);
      setUploadDone(true);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (uploadDone) {
      console.log('redirecting...');
      clearInterval(intervalIdRef.current);
      setUploadingState(false); // Reset the state

      const queryParams = new URLSearchParams();

      const targetFile = files.find(file => file.name.endsWith('.obj') || file.name.endsWith('.gltf'));
      if (targetFile) {
        queryParams.append('filename', targetFile.fullPath);
      }

      window.location.href = `/new-render?${queryParams.toString()}`;
    }
  }, [uploadDone]);

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  return (
    <div style={{ padding: '2% 2%' }}>
      <h2 className="v-heading mb-2 text-h3 text-sm-h3" style={{ margin: '2% 2%' }}>
        Upload files and folders
      </h2>
      <p style={{ padding: '1% 3%' }}>
        '.obj', '.mtl', and '.jpg' etc.
      </p>

      <div {...getRootProps()} className="dropzone" style={{ border: '2px dashed #1976D2', padding: '2rem', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <p>Drag & drop files and folders here, or click to select files</p>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}>
        {files.map((file, index) => (
          <div key={index} className="chip" style={{ margin: '0.5rem', backgroundColor: '#1976D2', color: 'white', padding: '0.5rem', borderRadius: '16px' }}>
            {file.fullPath}
          </div>
        ))}
      </div>

      {files.length > 0 && (
        <button onClick={handleUpload} style={{ marginTop: '1rem', padding: '0.5rem 1rem', backgroundColor: '#1976D2', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Upload
        </button>
      )}

      {uploadingState && (
        <div style={{ marginTop: '2rem', fontWeight: 'bold', color: '#FF0000' }}>
          UPLOADING... Please wait!
        </div>
      )}

      <hr />
    </div>
  );
};

export default FileUploadPage;
